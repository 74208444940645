// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.


// console.log("Hi from app/javascript/groups.js")

var initialize = function(){

}//end initialize function

function submitFormOnClick(onClickClass) {
  if ( onClickClass ){
    $("." + onClickClass).on('click', function(e) {
      let formName = $(e.target).data('form-name')
      let form =  document.getElementsByName(formName)[0]
      if ( form ){
        // $(form).trigger('submit.rails')
        Rails.fire(form, 'submit');
      }
    });
  }
}


//initialize the script on page:load which occurs after rails turbolinks link_to
$(document).ready(initialize);
//initialize the script on page:load which occurs after rails turbolinks link_to
$(document).on('page:load', initialize);
