function initializeMeasurementModalData () {
  $('.measurement-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)
    // Extract info from data-* attributes
    // Extract member name
    var member = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')
    // Extract existing measurements path
    var existingMeasurementsPath = button.data('existing-measurements-path')

    var modal = $(this)
    modal.find('.modal-title > b').text(member)

    // Retreive element for new measurement form
    const formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)

    // Retreive element fot existing measurments
    var existingMeasurementsTarget = modal.find('.modal-body > .existing-measurements')
    // Load partial to modal element
    loadRemoteHTML(existingMeasurementsPath, existingMeasurementsTarget)
  })

  $('.measurement-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

// Extract data for weight modal dialog from HTML
function initializeWeightModalData () {
  $('#weight-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)
    // Extract info from data-* attributes
    // Extract member name
    var member_name = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')
    // Extract existing measurements path
    // var existingMeasurementsPath = button.data('existing-measurements-path')

    var modal = $(this)
    // Show member name on modal
    modal.find('.modal-title').text(member_name)

    // Retreive element for new measurement form
    var formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)

    // Retreive element fot existing measurments
    // var existingMeasurementsTarget = modal.find('.modal-body > .existing-measurements')
    // Load partial to modal element
    // loadRemoteHTML(existingMeasurementsPath, existingMeasurementsTarget)
  })

  $('.weight-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

// Extract data for height modal dialog from HTML
function initializeHeightModalData () {
  $('#height-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)
    // Extract info from data-* attributes
    // Extract member name
    var member_name = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')
    // Extract existing measurements path
    // var existingMeasurementsPath = button.data('existing-measurements-path')

    var modal = $(this)
    // Show member name on modal
    modal.find('.modal-title').text(member_name)

    // Retreive element for new measurement form
    var formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)

    // Retreive element fot existing measurments
    // var existingMeasurementsTarget = modal.find('.modal-body > .existing-measurements')
    // Load partial to modal element
    // loadRemoteHTML(existingMeasurementsPath, existingMeasurementsTarget)
  })

  $('.height-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

// Extract data for waist modal dialog from HTML
function initializeWaistModalData () {
  $('#waist-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)
    // Extract info from data-* attributes
    // Extract member name
    var member_name = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')
    // Extract existing measurements path
    // var existingMeasurementsPath = button.data('existing-measurements-path')

    var modal = $(this)
    // Show member name on modal
    modal.find('.modal-title').text(member_name)

    // Retreive element for new measurement form
    var formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)

    // Retreive element fot existing measurments
    // var existingMeasurementsTarget = modal.find('.modal-body > .existing-measurements')
    // Load partial to modal element
    // loadRemoteHTML(existingMeasurementsPath, existingMeasurementsTarget)
  })

  $('.waist-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

// Extract data for blood preasure measurement modal dialog from HTML
function initializePressureModalData () {
  $('#pressure-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)

    // Extract info from data-* attributes
    // Extract member name
    var member_name = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')

    // Show member name on modal
    var modal = $(this)
    modal.find('.modal-title').text(member_name)

    // Retreive element for new measurement form
    var formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)
  })

  $('.pressure-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

// Extract data for payment modal dialog from HTML
function initializePaymentModalData () {
  var exercise;
  $('#payment-modal').on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var button = $(event.relatedTarget)

    // Extract info from data-* attributes
    // Extract member name
    var member_name = button.data('member-name')
    // Extract new form path
    var formPath = button.data('form-path')
    exercise = button.data('exercise-date')
    console.log(exercise)
    // Show member name on modal
    var modal = $(this)
    modal.find('.modal-title').text(member_name)

    // Retreive element for new payment form
    var formTarget = modal.find('.modal-body > .form')
    // Load partial to modal element
    loadRemoteHTML(formPath, formTarget)


  })

  $('#payment-modal').on('shown.bs.modal', function (event) {
    // console.log("test")
    var input_fields = $(".input-fields")
    console.log("Adding...")

    input_fields.append(`<input id="occured_on_year" name="occured_on_year" type="hidden" value=${exercise.split('-')[0]} >`)
    input_fields.append(`<input id="occured_on_month" name="occured_on_month" type="hidden" value=${exercise.split('-')[1]} >`)
  })

  $('#payment-modal').on('hide.bs.modal', function (event) {
    resetMeasurementPlaceholders()
  })
}

function resetMeasurementPlaceholders () {
  $(".modal-body > .form").text("Nalagam vnosna polja...")
  $(".modal-body > .existing-measurements").text("Nalagam obstoječe meritve...")
}

function loadRemoteHTML (path, target, dataType) {
  if(!dataType) { dataType = 'html' }

  $.ajax({
    url: path,
    dataType: dataType
  }).done(function (data) {
    target.html(data)
  });
}

function renderFormErrors(form, model_name, errors) {
//  this.clear_form_errors()

  $.each(errors, function(field, messages){
    input = form.find('input, select, textarea').filter(function(){
      name = $(this).attr('name')
      if (name){
        var regex = model_name + '\\[' + field + '.*\\]'
        return name.match(new RegExp(regex))
      }
    })

    clearFormErrors(form, "div.invalid-feedback")

    input.addClass('is-invalid')
    input.closest('.form-group').addClass('is-invalid')
    input.parent().append('<div class="invalid-feedback">' + messages + '</div>')
  })
}

function clearFormErrors(form, errorElementSelector){
  form.find(errorElementSelector).remove()
}

function toggleMemberPresenceButtonText(button, present) {
  if ( button ){
    let buttonText = $(button).find('.label-text')
    if ( present ){
      // member is present
      buttonText.text('prisoten')
    } else {
      // member NOT is present
      buttonText.text('odsoten')
    }
  }
}

var initialize = function(){
  //Default to JSON responses for remote calls
  let form = document.querySelector('form')

  $(form).on("ajax:success", function(e){
    let data = e.detail[0]
    if ( data && data.redirect ) {
      window.location.href = data.redirect
    }
  }).on("ajax:error", function(e){
    let data = e.detail[0]
    renderFormErrors(form, 'exercise', data)
  })

  $("#exercise_coach_id").change(function() {
    if ( this.value ){
      Rails.fire(form, 'submit')
    }
  })

  $('label.toggle').on('click', function () {
    // toggle style
    $(this).toggleClass('btn-success btn-danger active');

    // toggle checkbox
    var checkbox = $(this).find('[type=checkbox]');
    checkbox.prop('checked',!checkbox.prop('checked'));

    Rails.fire(form, 'submit')

    toggleMemberPresenceButtonText(this, checkbox.prop('checked'))
    return false;
  });

  initializeMeasurementModalData()
  // Initalize extract data for weight modal dialog
  initializeWeightModalData()
  // Initalize extract data for height modal dialog
  initializeHeightModalData()
  // Initalize extract data for height modal dialog
  initializeWaistModalData()
  // Initalize extract data for pressure modal dialog
  initializePressureModalData()
  // Initalize extract data for payment modal dialog
  initializePaymentModalData()
}//end initialize function

//initialize the script on page:load which occurs after rails turbolinks link_to
$(document).ready(initialize);
//initialize the script on page:load which occurs after rails turbolinks link_to
$(document).on('page:load', initialize);
